@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap");
/* You can add global styles to this file, and also import other style files */

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
    font-family: "Inter", Helvetica, Arial, sans-serif;
}

body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* fix for webkit mobile */

html {
    height: -webkit-fill-available;
}

body {
    height: 100%;
    min-height: -webkit-fill-available;
}

body {
    background: #f5f5f5;
}

@media screen and (min-width: 768px) {
    body {
        background: #f9f9f9 !important;
    }

    body:has(survey-page),
    body:has(survey-invitation-page),
    body:has(feeling-page),
    body:has(app-rating-modal) {
        background: #fff !important;
    }
}

h1 {
    font-size: 1.88rem;
    font-weight: 500;
    margin-bottom: 0;
}

h2 {
    font-size: 1rem;
    font-weight: 500;
}

.m-0-auto {
    margin: 0 auto;
}

.-z-1 {
    z-index: -1;
}

.card-shadow-bottom {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.01);
}

.card-shadow-top {
    box-shadow: 0px -4px 4px 0px rgba(0, 0, 0, 0.01);
}

.next__appointment-bg .appointment-day {
    font-weight: bold;
    color: #000;
}

.next__appointment-bg .appointment-hour {
    color: #000;
}

.next__appointment-bg .appointment-join__icon {
    fill: #577881 !important;
}

.next__appointment-bg .appointment-join__text {
    color: #577881 !important;
}

.next__appointment-bg .appointment-icon {
    fill: #000 !important;
}

.local-video div,
.local-video video {
    border-radius: 10px;
}

.calendar-pin.is-active {
    background-color: #cbdfcb !important;
}

.custom-bg-white::before {
    content: "";
    position: absolute;
    inset: 0;
    background-color: #fff;
    /*height: calc(100% + 50px);*/
    height: calc(100%);
    z-index: -1;
    border-top-left-radius: 32px;
    border-top-right-radius: 32px;
}

.custom-bg-white-translate::before {
    transform: translateY(70px);
}

.custom-bg-white-translate.h-full {
    height: calc(100% - 70px)!important;
}

.bg-section-data-red {
    background: #f87171;
    background: linear-gradient(to bottom, #f87171 50%, #f5f5f5 50%);
}

.bg-section-data-purple {
    background: #a78bfa;
    background: linear-gradient(to bottom, #a78bfa 50%, #f5f5f5 50%);
}

.bg-section-data-green {
    background: #6da66d;
    background: linear-gradient(to bottom, #6da66d 50%, #f5f5f5 50%);
}

.bg-section-data-yellow {
    background: #fdba74;
    background: linear-gradient(to bottom, #fdba74 50%, #f5f5f5 50%);
}

.bg-section-messages-cyan {
    background: rgb(245, 245, 245);
    background: linear-gradient(180deg,
            rgba(245, 245, 245, 1) 0%,
            rgba(250, 253, 254, 1) 100%);
}

.font-titillium {
    font-family: "Titillium Web", "Inter", Helvetica, Arial, sans-serif;
}

.separate-dial-code {
    width: 100%;
}

profile-page input:not(:read-only),
profile-page select:not(:disabled) {
    padding: 20px 16px;
    border: solid 1px #eee;
    border-radius: 0.5rem;
    width: 100%;
    background-color: #fff;
}

billing-info-page input:not(:read-only),
billing-info-page select:not(:disabled) {
    padding: 20px 16px;
    border: solid 1px #eee;
    border-radius: 0.5rem;
    width: 100%;
    background-color: #fff;
}

add-health-data-page input:not(:read-only),
add-health-data-page select:not(:disabled) {
    padding: 20px 16px;
    border: solid 1px #eee;
    border-radius: 0.5rem;
    width: 100%;
    background-color: #fff;
}

.ngx-tel input {
    padding: 20px 16px;
    border: solid 1px #eee;
    border-radius: 0.5rem;
    width: 100%;
}

button:disabled {
    background: #cecece !important;
    color: #fff !important;
}

.bg-box-gradient {
    background: linear-gradient(180deg, #ffffff 0%, #f5f5f5 100%);
}

.bg-blue-gradient {
    background: linear-gradient(180deg,
            rgba(5, 90, 140, 1) 0%,
            rgba(7, 120, 187, 1) 100%);
}

.bg-linear-gradient {
    background: linear-gradient(270deg,
            rgba(250, 250, 250, 1) 0%,
            rgba(255, 255, 255, 1) 80%);
}

.bg-linear-gradient-reverse {
    background: linear-gradient(90deg,
            rgba(250, 250, 250, 1) 0%,
            rgba(255, 255, 255, 1) 80%);
}

.photo-user-container .photo-user-item {
    transform: translateX(6px);
    z-index: 7;
}

.photo-user-container .photo-user-item:last-child {
    transform: translateX(0) !important;
    z-index: 6;
}

.custom-bg-illustration-blue::before {
    content: "";
    position: absolute;
    background: #a9c4b960;
    inset: 0;
    height: 100vh;
    z-index: -1;
    transform: translateY(-50%);
}

.custom-bg-illustration-green::before {
    content: "";
    position: absolute;
    background: #e9f2e9;
    inset: 0;
    height: 100vh;
    z-index: -1;
    transform: translateY(-50%);
}

.custom-bg-illustration-red::before {
    content: "";
    position: absolute;
    background: #fee2e2;
    inset: 0;
    height: 100vh;
    z-index: -1;
    transform: translateY(-50%);
}

.tier-card.tier-1 {
    background-image: url(assets/img/tier/tier-1-bg.png);
}

.tier-card.tier-2 {
    background-image: url(assets/img/tier/tier-2-bg.png);
}

.tier-card.tier-3 {
    background-image: url(assets/img/tier/tier-3-bg.png);
}

.tier-card {
    object-fit: cover;
    background-position: center center;
}

hr.dashed {
    border: none;
    height: 1px;
    background: #1b1b181a;
    background: repeating-linear-gradient(90deg,
            #1b1b181a,
            #1b1b181a 6px,
            transparent 6px,
            transparent 12px);
    width: 100vw;
    max-width: 768px;
}

/* Ripple effect not atomic :( */
button.bg-primary {
    background-position: center;
    transition: background 0.8s;
}

button.bg-primary:hover {
    background: #82B4C0 radial-gradient(circle, transparent 1%, #82B4C0 1%) center/15000%;
}

button.bg-primary:active {
    background-color: #82B4C0;
    background-size: 100%;
    transition: background 0s;
}

.scrollbar-hidden::-webkit-scrollbar {
    display: none;
}

section::-webkit-scrollbar,
div::-webkit-scrollbar {
    display: none;
}

/* Ripple effect */
.ripple {
    background-position: center;
    transition: background 0.8s;
}

.ripple:hover {
    background: #82B4C0 radial-gradient(circle, transparent 1%, #82B4C0 1%) center/15000%;
}

.ripple:active {
    background-color: #82B4C0;
    background-size: 100%;
    transition: background 0s;
}

.button-loader {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: block;
    margin: 5px auto;
    position: relative;
    color: #000;
    left: -100px;
    box-sizing: border-box;
    animation: shadowRolling 2s linear infinite;
}

.button-loader-black {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: block;
    margin: 5px auto;
    position: relative;
    color: #fff;
    left: -100px;
    box-sizing: border-box;
    animation: shadowRollingBlack 2s linear infinite;
}

.survey-bg:has(survey-page),
.survey-bg:has(survey-invitation-page),
.survey-bg:has(expired-initial-survey-page),
.survey-bg:has(life-wheel-survey-page),
.survey-bg:has(feeling-page),
.survey-bg:has(app-rating-page) {
    background-color: rgb(245,245,245)!important;
}

@keyframes shadowRolling {
    0% {
        box-shadow: 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0),
            0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0);
    }

    12% {
        box-shadow: 100px 0 white, 0px 0 rgba(255, 255, 255, 0),
            0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0);
    }

    25% {
        box-shadow: 110px 0 white, 100px 0 white, 0px 0 rgba(255, 255, 255, 0),
            0px 0 rgba(255, 255, 255, 0);
    }

    36% {
        box-shadow: 120px 0 white, 110px 0 white, 100px 0 white,
            0px 0 rgba(255, 255, 255, 0);
    }

    50% {
        box-shadow: 130px 0 white, 120px 0 white, 110px 0 white, 100px 0 white;
    }

    62% {
        box-shadow: 200px 0 rgba(255, 255, 255, 0), 130px 0 white, 120px 0 white,
            110px 0 white;
    }

    75% {
        box-shadow: 200px 0 rgba(255, 255, 255, 0),
            200px 0 rgba(255, 255, 255, 0), 130px 0 white, 120px 0 white;
    }

    87% {
        box-shadow: 200px 0 rgba(255, 255, 255, 0),
            200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0),
            130px 0 white;
    }

    100% {
        box-shadow: 200px 0 rgba(255, 255, 255, 0),
            200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0),
            200px 0 rgba(255, 255, 255, 0);
    }
}

bottom-navigation-bar a {
    position: relative;
}

bottom-navigation-bar .active-notification::after {
    content: "";
    position: absolute;
    top: -5px;
    right: -5px;
    width: 8px;
    height: 8px;
    background-color: #c43e3e;
    border-radius: 50%;
}
